/// <reference path="../reportwebvitals.js" />
import axios from "axios";
import Loader from "./Loader";
import React, { Component } from 'react';
import { Collapse, CardBody, Card, CardHeader, Button } from 'reactstrap';
import { useParams } from "react-router-dom";
function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

export class GetCustomer extends Component {
    static displayName = GetCustomer.name;
    constructor(props) {
        super(props);
        let { customerId } = this.props.params;
        this.state = { customerId: customerId, details: false, loading: true, error: false, errorMessage: "", shouldDirect: false };
    }

    componentDidMount() {
          this.populateCustomerData(this.state.customerId);
    }

    toggle = () => {
        this.setState({ details: !this.state.details });
    }

    getLoginLink(customerId) {
        const rootHostname = window.location.host == 'localhost' ? 'dev.eci.services' : window.location.host;
        return `https://portal.${rootHostname}/?customerId=${customerId}`;
    }

    renderCustomerCard = () => {
        if (this.state && this.state.customer) {
            let customer = this.state.customer

            return (
                <>
                    <Card>
                        <CardHeader>
                            <table className='table table-striped' aria-labelledby="tableLabel">
                                <tr><td>Source</td>                 <td>{customer.source} ({new Date(customer.importDate).toLocaleString()})</td></tr>
                                <tr><td>Customer ID</td>            <td>{customer.customerId}</td></tr>
                                <tr><td>Account Name</td>           <td>{customer.accountName}</td></tr>
                                <tr><td>Account Number</td>         <td>{customer.accountNumber}</td></tr>
                            </table>
                        </CardHeader>
                            <CardBody>
                                <table className='table table-striped' aria-labelledby="tableLabel">
                                    <tr><td>SQL Instance</td>           <td>{customer.sqlInstance}</td></tr>
                                    <tr><td>SQL Port</td>               <td>{customer.sqlPort}</td></tr>
                                    <tr><td>SQL</td>                    <td>{customer.sql}</td></tr>
                                    <tr><td>App</td>                    <td>{customer.app}</td></tr>
                                    <tr><td>Web</td>                    <td>{customer.web}</td></tr>
                                    <tr><td>Prod. DB</td>               <td>{customer.prodDb}</td></tr>
                                    <tr><td>Test. DB</td>               <td>{customer.testDb}</td></tr>
                                    <tr><td>AddOns</td>                 <td>{customer.addons}</td></tr>
                                    <tr><td>PIP Url</td>                <td>{customer.pip}</td></tr>
                                    <tr><td>EInfo Url</td>              <td>{customer.customerEinfoUrl}</td></tr>
                                    <tr><td>RT Utl</td>                 <td>{customer.rtUtl}</td></tr>
                                    <tr><td>RSM Url</td>                <td>{customer.rsmUrl}</td></tr>
                            </table><br />
                            <a href={this.getLoginLink(customer.customerId)}>
                                <Button color="primary">Login</Button>
                            </a>
                            </CardBody>
                    </Card>
                    <Card>
                        <CardHeader>
                            <div style={{ cursor: 'pointer' }} onClick={this.toggle}>Customer details {this.state.details === true ? "(hide)" : "(show more)" }</div>
                        </CardHeader>
                        <Collapse isOpen={this.state ? this.state.details : false}>
                            <CardBody>
                                <table className='table table-striped' aria-labelledby="tableLabel">
                                    <tr><td>Cloud2</td>                 <td>{customer.cloud}</td></tr>
                                    <tr><td>E-Automate Lic.</td>        <td>{customer.eautoLicenses}</td></tr>
                                    <tr><td>RDP Users</td>              <td>{customer.rdpUsers}</td></tr>
                                    <tr><td>Existing Customer</td>      <td>{customer.existing}</td></tr>
                                    <tr><td>Assigned To</td>            <td>{customer.assignedTo}</td></tr>
                                    <tr><td title="Provision Start Date">Prov. Start Date</td>       <td>{customer.provisionStart}</td></tr>
                                    <tr><td>Infrastructure</td>         <td>{customer.infastructureProvisioned}</td></tr>
                                    <tr><td>Go Live</td>                <td>{customer.goLive}</td></tr>
                                    <tr><td>Timezone</td>               <td>{customer.tz}</td></tr>
                                    <tr><td>Country</td>                <td>{customer.country}</td></tr>
                                    <tr><td>EInfo Mod.</td>             <td>{customer.einfoMod}</td></tr>
                                    <tr><td>3rd Party IP Addr. Port</td><td>{customer.thirdPartyIpAddPort}</td></tr>
                                    <tr><td>3rd Party Username</td>     <td>{customer.thirdPartyUsername}</td></tr>
                                    <tr><td>3rd Party Password</td>     <td>{customer.thirdPartyPassword}</td></tr>
                                    <tr><td>SendGrid</td>               <td>{customer.sendgrid}</td></tr>
                                    <tr><td>SendGrid Domains</td>       <td>{customer.sendgridDomains}</td></tr>
                                    <tr><td>SendGrid Contact</td>       <td>{customer.sendgridContact}</td></tr>
                                    <tr><td>Impl. Contact Name</td>     <td>{customer.contact}</td></tr>
                                    <tr><td>Impl. Contact Name</td>     <td>{customer.contact}</td></tr>
                                    <tr><td>Impl. Contact Phone</td>    <td>{customer.contactPhone}</td></tr>
                                    <tr><td>Impl. Attachments</td>      <td>{customer.attach}</td></tr>
                                    <tr><td>Impl. Notes</td>            <td>{customer.notes}</td></tr>
                                    <tr><td>Impl. SQL Version</td>      <td>{customer.sqlVersion}</td></tr>
                                    <tr><td>Impl. ACS Dealer Id</td>    <td>{customer.acsDealerId}</td></tr>
                                    <tr><td>Impl. ACS Install Key</td>  <td>{customer.acsInstallKey}</td></tr>
                                    <tr><td>Impl. Additional Notes</td> <td>{customer.addtionalNotes}</td></tr>
                                    <tr><td>Source</td>                 <td>{customer.source}</td></tr>
                                    <tr><td>Import Date</td>            <td>{customer.importDate}</td></tr>
                                </table>
                            </CardBody>
                        </Collapse>
                    </Card>
                </>
            );
        }
    }
  
    render() {
        let contents = this.state.loading
            ? <Loader message="Loading customer please wait.."></Loader>
            : this.renderCustomerCard();

         return (
            <div>
                {!this.state.loading && this.state.error ?
                    <div className="alert alert-danger" role="alert">
                        Error occurred while loading customer. {this.state.errorMessage}
                    </div> : null}
                 <br />
                 <h1 id="tableLabel">{this.state && this.state.customer ? this.state.customer.accountName : "Customer"}</h1>
                 { contents }
             </div>
        );
    }
 

    async populateCustomerData(customerId) {
        await axios.get(`customer/${customerId}`)
            .then(data => {
                // handle successful response data
                this.setState({
                    details: false,
                    customer: data.data,
                    loading: false,
                    error: false
                })
            })
            .catch(error => {
                // handle any error that occurred during the POST
                // extract proper error message
                let errorMessage = "Unknown error occurred.";
                if (error) {
                    console.error(error);
                    if (error.response) {
                        // handle successful response data
                        errorMessage = error.response.data.message ? error.response.data.message : error.message;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
                // set state
                this.setState({
                    errorMessage: errorMessage,
                    loading: false,
                    error: true
                })
            });
    }
}
export default withParams(GetCustomer);