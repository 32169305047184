import { InteractionStatus } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { accessAsUser } from './authConfig';

export default function App() {
    const { instance, accounts, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const loginViaRedirect = useCallback(() => {
        instance.loginRedirect(accessAsUser)
            .catch((e) => console.log("error logging in", e));
    }, [instance]);

    useEffect(() => {
        console.log('Authenticating. inProgress=', inProgress, 'isAuthenticated=', isAuthenticated);
        if (inProgress !== InteractionStatus.None) return;

        if (!isAuthenticated && accounts.length === 0) {
            loginViaRedirect();
        }
    }, [inProgress]);

    return (
        <>
            <AuthenticatedTemplate>
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <button onClick={loginViaRedirect} className="btn btn-link text-muted text-small">Please wait, redirecting to login...</button>
            </UnauthenticatedTemplate>
        </>
    );
}
