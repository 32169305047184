import axios from "axios";
import Loader from "./Loader"
import React, { Component } from 'react';
export class GetSubscriptions extends Component {
    static displayName = GetSubscriptions.name;
    constructor(props) {
        super(props);
        this.state = { subscriptions: [], loading: true, error: false, errorMessage: "" };
    }
    componentDidMount() {
        this.populateSubscriptionData();
    }

    static renderSubscriptionsTable(subscriptions) {
        return (
            <table className='table table-striped' aria-labelledby="tableLabel">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Customer ID</th>
                        <th>Onboarding Instructions</th>
                        <th>SKU</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {subscriptions.map(subscription =>
                        <tr key={subscription.id}>
                            <td>{subscription.id}</td>
                            <td>{subscription.customerId}</td>
                            <td>{subscription.onboardingInstructions}</td>
                            <td>{subscription.sku}</td>
                            <td>{subscription.status}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }

    render() {
        let contents = this.state.loading
            ? <Loader message="Loading subscriptions please wait.."></Loader>
            : GetSubscriptions.renderSubscriptionsTable(this.state.subscriptions);
       
        return (
            <div>
                {!this.state.loading && this.state.error ?
                    <div className="alert alert-danger" role="alert">
                        Error occurred while loading subscriptions. {this.state.errorMessage}
                    </div> : null}
                <br />
                <h1 id="tableLabel">Subscriptions Table</h1>
                {contents}
            </div>
        );
    }
    async populateSubscriptionData() {
        await axios.get('subscription')
            .then(data => {
                // handle successful response data
                this.setState({
                    subscriptions: data.data,
                    loading: false,
                    error: false
                })
            })
            .catch(error => {
                // handle any error that occurred during the POST
                // extract proper error message
                let errorMessage = "Unknown error occurred.";
                if (error) {
                    console.error(error);
                    if (error.response) {
                        // handle successful response data
                        errorMessage = error.response.data.message ? error.response.data.message : error.message;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
                // set state
                this.setState({
                    errorMessage: errorMessage,
                    loading: false,
                    error: true
                })
            });
    }
}
export default GetSubscriptions;