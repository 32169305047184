import axios from "axios";
import React, { Component } from 'react';
import ViewCustomer from "./ViewCustomer";
import ViewProduct from "./ViewProduct";
import Loader from "./Loader";
import ViewSubscriptions from "./ViewSubscriptions";
import { Typeahead } from "react-bootstrap-typeahead"

export class AddSubscription extends Component {
    static displayName = AddSubscription.name;
    constructor(props) {
        super(props);
        this.state = {
            subscription: {},
            subscriptions: [],
            customer: null,
            customers: [],
            product: null,
            products: [],
            customerLoading: true,
            productLoading: true,
            errorMessage: "",
            error: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentDidMount() {
        this.getCustomerList();
        this.getProductList();
    }
    handleSubmit(e) {
        e.preventDefault();
        // create subscription object
        let sub = {
            id: "",
            customerId: String(this.state.customer.customerId),
            sku: String(this.state.product.sku),
            onboardingInstructions: "instructions",
            status: "Active",
        }

        // check if customer/product is valid
        
        // POST our new customer object 
        axios.post('subscription/add', sub)
        .then(response => {
            // handle sucessfull response data
            console.log("subscription/add", response);
            // set state to created customer with new ID
            this.setState({ subscription: response.data });
            alert('Subscription Created successfully');
        })
        .catch(error => {
            // handle any error that occurred during the POST
            console.error(error.message + ':\n' + error.stack);
            alert(error.message);
        })
    }

    selectedCustomer(customer) {
        // this comes in as an array to support multi-select
        // however, we are not allowing multi-select so it is safe to access index 0.
        this.setState({ customer: customer[0] });
        return customer;
    }

    selectedProduct(e, product) {
        e.preventDefault();
        this.setState({ product: product });
        return product;
    }

    renderCustomersSearch(customers) {  
        if (this.state.customerLoading) {
            return (<Loader message="Loading customers please wait.."></Loader>);
        }
        else {
            return (
                <div className="row">
                    <h6>Search Customer:</h6>
                    <Typeahead
                        id="customerlist"
                        labelKey="accountName"
                        onChange={(selected) => {
                            this.selectedCustomer(selected);
                        }}
                        options={customers}
                        />
                </div>
            );
        }
    }

    renderProductsDropdown(products) {
        if (this.state.productLoading) {
            return (<Loader message="Loading products please wait.."></Loader>);
        }
        else {
            return (
                <div className="dropdown">
                    <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        {this.state.product != null ? this.state.product.productName : "Select Product"}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {products.map(product =>
                            <li key={product.sku}><a onClick={(e) => this.selectedProduct(e, product)} className="dropdown-item" key={product.sku} href="#/">{product.productName}</a></li>
                        )}
                    </ul>
                </div>
            );
        }
    }

    render() {
        let customerContents = this.renderCustomersSearch(this.state.customers);
        let productContents = this.renderProductsDropdown(this.state.products)
        let hasCustomer = this.state.customer != null;
        let hasProduct = this.state.product != null;
        return (
            <div>
                <div>
                    {customerContents}
                    <br/>
                    {hasCustomer ? (
                        <div>
                            <ViewCustomer customer={this.state.customer}></ViewCustomer>
                            <br />
                            <ViewSubscriptions customer={this.state.customer}></ViewSubscriptions>
                            <br />
                            <div className="card">
                                <div className="card-header">
                                    <h6>Add new product subscription</h6>
                                </div>
                                <div className="card-body">
                                    {productContents}
                                    {hasProduct ? (
                                        <div>
                                            <ViewProduct product={this.state.product}></ViewProduct>
                                        </div>
                                    ) : (
                                        null
                                    )}
                                </div>
                                <button className="btn btn-outline-primary" type="submit" onClick={(e) => this.handleSubmit(e)}>Add Subscription</button>
                            </div>
                        </div>
                    ) : (
                        null
                    )}
                </div>

            </div>
        );
    }

    // list of customers
    async getCustomerList() {
        const response = await axios.get("customers");
        this.setState({ customers: response.data, customerLoading: false });
    }

    // list of products
    async getProductList() {
        const response = await axios.get("product");
        this.setState({ products: response.data, productLoading: false });
    }

    // check for valid customer
    customerChecker(customerId) {
        for (var c of this.state.customers) {
            if (customerId === c.customerId) {
                return true;
            }
        }
        return false;
    }

    //check for valid product
    productChecker(sku) {
        for (var p of this.state.products) {
            if (sku === p.sku) {
                return true;
            }
        }
        return false;
    }

}
export default AddSubscription;