import axios from "axios";
import Loader from "./Loader";
import React, { Component } from 'react';

export class ImportCustomers extends Component {
    static displayName = ImportCustomers.name;
    constructor(props) {
        super(props);
        this.state = {
            file: {},
            fileName: "",
            importType: { key: "na", display: "N/A" },
            message: "",
            importComplete: false,
            importSucceeded: false,
            importing: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    saveFile = (e) =>
    {
        this.setState({
            file: e.target.files[0],
            fileName: e.target.files[0].name
        })
    }

    saveSalesforceFile = (e) => {
        this.setState({
            file: e.target.files[0],
            filename: e.target.files[0].name
        })
    }

    selectedImportType(e) {
        e.preventDefault();
        this.setState({
            importType: {
                key: e.target.parentNode.id,
                display: e.target.textContent
            } 
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            importing: true
        })
     
        // POST excel file
        axios.postForm('customer/import', {
            File: this.state.file,
            FileName: this.state.fileName,
            ImportType: this.state.importType.key
        },
            {
                timeout: 2 * 60 * 1000
            })
            .then(data => {
                // handle successful response data
                this.setState({
                    message: data.data.message,
                    importComplete: true,
                    importSucceeded: true,
                    importing: false
                })
            })
            .catch(error => {
                // handle any error that occurred during the POST
                let errorMessage = "Unknown error occurred.";
                if (error) {
                    console.error(error);
                    if (error.response) {
                        // handle successful response data
                        errorMessage = error.response.data.message ? error.response.data.message : error.message;
                    }
                    else {
                        errorMessage = error.message;
                    }
                }
                this.setState({
                    message: errorMessage,
                    importComplete: true,
                    importSucceeded: false,
                    importing: false
                })
            });
    };


    renderCustomerImportForm() {
        return (
            <form className="customerForm" onSubmit={this.handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="formFile" className="form-label">Import customer excel file</label>
                        <div className="input-group mb-3">
                            <input className="form-control" type="file" id="formFile" onChange={this.saveFile} />
                            <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                {this.state.importType.display}
                            </button>
                            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
                                <li id="NA"><a className="dropdown-item" onClick={(e) => this.selectedImportType(e)} href="#/">N/A</a></li>
                                <li id="Duanes" ><a className="dropdown-item" onClick={(e) => this.selectedImportType(e)} href="#/">Duane's</a></li>
                                <li id="SalesForce"><a className="dropdown-item" onClick={(e) => this.selectedImportType(e)} href="#/">Salesforce</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {this.state.importing 
                    ? <Loader message="Uploading and performing import please wait.."></Loader>
                    : <button type="submit" className="btn btn-primary">Upload</button>
                }
                <br />
                <br />
                {this.state.importComplete && this.state.importSucceeded && !this.state.importing ?
                    <div className="alert alert-success" role="alert">
                        {this.state.message}
                    </div> : null}
                {this.state.importComplete && !this.state.importSucceeded && !this.state.importing ?
                    <div className="alert alert-danger" role="alert">
                        {this.state.message}
                    </div> : null}
            </form>
        );
    }



    render() {
        return this.renderCustomerImportForm();
    }

}
export default ImportCustomers;