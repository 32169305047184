import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: "97997768-f6b1-4c3b-99d7-73c0fdf11330",
        authority: 'https://login.microsoftonline.com/3876dbb5-741c-4fe6-9981-06fa00a6d682',

        // Redirect to /app/ because AzureAD doesn't support wildcards in allowed redirect URIs.
        // Allowed URIs are configured at https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Authentication/appId/97997768-f6b1-4c3b-99d7-73c0fdf11330/isMSAApp~/false
        // TODO: dynamically add ?redirect={window.location.path} if allowed, so we can keep users on the current page
        redirectUri: window.location.origin + '/app/',
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: string, containsPii: any) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default: /* ignore */ break;
                }
            },
        },
    },
};
  
// Can be found in the API Permissions of the ASP.NET Web API
export const accessAsUser = {
    scopes: ["api://97997768-f6b1-4c3b-99d7-73c0fdf11330/access_as_user"],
};