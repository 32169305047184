const ViewCustomer = props => {
    let { customer } = props;

    if (customer != null) {
        return <div className="card">
            <div className="card-header">
                <h6>{customer.accountName}'s Information</h6>
            </div>
            <div className="card-body">
                <div className="customerView">
                    <div className="form-row">
                        <div className="form-group">
                            <label>Company Name</label>
                            <p className="form-control" id="companyName">{customer.accountName}</p>
                        </div>
                    </div>
                    <div className="input-group">
                        <div className="form-group col-md-10">
                            <label>Company Name</label>
                            <p className="form-control" id="companyName">{customer.accountName}</p>
                        </div>
                        <div className="form-group col-md-2">
                            <label>Account Number</label>
                            <p className="form-control" id="accoutnNumber">{customer.accountNumber}</p>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="input-group">
                            <div className="form-group col-md-6">
                                <label>Country</label>
                                <p id="country" className="form-control">{customer.country ?? "Unknown"}</p>
                            </div>
                            <div className="form-group col-md-6">
                                <label>Phone</label>
                                <p className="form-control" id="phone">{customer.contactPhone}</p>
                            </div>
                        </div>
                    </div>
                    <div className="input-group">
                        <div className="form-group col-md-6">
                            <label>Customer Contact</label>
                            <p className="form-control" id="email">{customer.contact}</p>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Website</label>
                            <p className="form-control" id="website">{customer.web}</p>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
        </div>
            ;
    } else {
        return "";
    }
};
export default ViewCustomer;