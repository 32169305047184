import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import axios from "axios";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { msalConfig, accessAsUser } from "./authConfig";
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

axios.defaults.baseURL = "/app/api/";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = axios.defaults.headers.put["Content-Type"] = "application/json";
axios.interceptors.request.use(
    async (response) => {
        const account = msalInstance.getAllAccounts()[0];
        const msalResponse = await msalInstance.acquireTokenSilent({
            ...accessAsUser,
            account: account,
        });
        response.headers['Authorization'] = `Bearer ${msalResponse.accessToken}`;
        return response;
    },
    (err) => {
        return Promise.reject(err);
    }
);

root.render(
    <BrowserRouter basename={baseUrl}>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
  </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
