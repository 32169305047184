import axios from "axios";
import React, { Component } from 'react';

export class AddCustomers extends Component {
    static displayName = AddCustomers.name;
    constructor(props) {
        super(props);
        this.state = { customer: {} };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = (e) => {
        e.preventDefault();
        // create customer object
        let cust = {
            customerId: "",
            customerName: e.target.companyName.value,
            customerAddress1: e.target.address.value,
            customerAddress2: e.target.address2.value,
            customerCountry: e.target.country.value,
            customerState: e.target.state.value,
            customerCity: e.target.city.value,
            customerPostalCode: e.target.zip.value,
            customerPhone: e.target.phone.value,
            customerFax: e.target.fax.value,
            customerWebsite: e.target.website.value,
            customerEmail: e.target.email.value
        }

        // POST our new customer object 
        axios.post('customer/add', cust)
            .then(data => {
                // handle sucessfull response data
                console.log("POST customer/add", data);
                // set state to created customer with new ID
                this.setState({ customer: data });
                alert('Customer Created successfully');
            })
            .catch(error => {
                // handle any error that occurred during the POST
                console.error(error.message + ':\n' + error.stack);
                alert(error.message);
            });
    };


    renderCustomersForm() {
        return (
            <form className="customerForm" onSubmit={this.handleSubmit}>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="companyName">Company Name</label>
                        <input type="text" className="form-control" id="companyName" placeholder="Company Name" />
                    </div>
                </div>
                <div className="input-group">
                    <div className="form-group col-md-10">
                        <label htmlFor="address">Address</label>
                        <input type="text" className="form-control" id="address" placeholder="1234 Main St" />
                    </div>
                    <div className="form-group col-md-2">
                        <label htmlFor="address2"></label>
                        <input type="text" className="form-control" id="address2" placeholder="Apartment, studio, or floor" defaultValue="" />
                    </div>
                </div>
                <div className="form-row">
                    <div className="input-group">
                        <div className="form-group col-md-4">
                            <label htmlFor="country">Country</label>
                            <select id="country" className="form-control">
                                <option defaultValue>USA</option>
                                <option>Canada</option>
                            </select>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="state">State</label>
                            <select id="state" className="form-control">
                                <option defaultValue>Choose...</option>
                                <option>...</option>
                            </select>
                        </div>
                        <div className="form-group col-md-2">
                            <label htmlFor="city">City</label>
                            <input type="text" className="form-control" id="city" placeholder="City" />
                        </div>

                        <div className="form-group col-md-2">
                            <label htmlFor="zip">Zip/Postal Code</label>
                            <input type="text" className="form-control" id="zip" placeholder="Zip/Postal Code" />
                        </div>
                    </div>
                </div>
                <div className="input-group">
                    <div className="form-group col-md-6">
                        <label htmlFor="phone">Phone</label>
                        <input type="text" className="form-control" id="phone" placeholder="1-111-111-1111" />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="fax">Fax</label>
                        <input type="text" className="form-control" id="fax" placeholder="1-111-111-1111" defaultValue="" />
                    </div>
                </div>
                <div className="input-group">
                    <div className="form-group col-md-6">
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control" id="email" placeholder="joe@internet.com" defaultValue="" />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="website">Website</label>
                        <input type="text" className="form-control" id="website" placeholder="www.mycompany.com" defaultValue="" />
                    </div>
                </div>
                <br />
                <button type="submit" className="btn btn-primary">Create</button>
            </form>
        );
    }



    render() {
        return this.renderCustomersForm();
    }

}
export default AddCustomers;