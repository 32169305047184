const ViewProduct = props => {
    let { product } = props;

    if (product != null) {
        return (
            <div>
                <table className='table table-striped' aria-labelledby="tableLabel">
                    <thead className="thead-dark">
                        <tr>
                            <th>SKU</th>
                            <th>Description</th>
                            <th>Name</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{product.sku}</td>
                            <td>{product.productDescription}</td>
                            <td>{product.productName}</td>
                            <td>{product.status}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
    
export default ViewProduct;