import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import GetCustomer from "./components/GetCustomer";
import GetAllCustomers from "./components/GetAllCustomers";
import AddCustomers from "./components/AddCustomers";
import GetSubscriptions from "./components/GetSubscriptions";
import AddSubscription from "./components/AddSubscription";
import { Home } from "./components/Home";
import ImportCustomers from "./components/ImportCustomers";
const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/customers',
        element: <GetAllCustomers />
    },
    {
        path: '/customer/:customerId',
        element: <GetCustomer />
    },
    {
        path: '/customer/add',
        element: <AddCustomers />
    },
    {
        path: '/subscription',
        element: <GetSubscriptions />
    },
    {
        path: '/subscription/add',
        element: <AddSubscription />
    },
    {
        path: '/customer/import',
        element: <ImportCustomers />
    },
];

export default AppRoutes;
